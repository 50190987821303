import React, { useEffect } from 'react';
import { Container } from './styles';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';

const link = 'https://fb.watch/wMjW_q8k-h';

const PodiakaSylamOboronyPage: React.FC = () => {
  useEffect(() => {
      // @ts-ignore
      if (window.FB) {
          // @ts-ignore
          window.FB.XFBML.parse();
      }
  }, []);

  const videoEmbedCode = `
  <div class="fb-video"
      style="width: 100%; max-width: 476px;"
       data-href="${link}"
       data-show-text="false">
  </div>
`;

  return (
    <Container>
      <Typography level="h1">Подяка Силам Оборони від команди Umbrella UA </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center',
      }}>
        <Box display="flex" justifyContent="center" sx={{ py: 1 }} flexDirection="column" alignContent="center">
        <div dangerouslySetInnerHTML={{ __html: videoEmbedCode }} />
        </Box>
      </Box>
    </Container>
  );
};

export default PodiakaSylamOboronyPage;
