import useFetch from '../../hooks/useFetch';
import Loader from '../../components/Loader/Loader';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {BackgroundImage, MediaUrl} from '../../common/utils/Media';
import ScrollToTop from '../../common/utils/ScrollToTop';
import {DronesPageResponseDataItem} from './DronesPage.model';
import DronesStats from '../../sections/DronesStats/DronesStats';
import HelpButton from '../../components/HelpButton/HelpButton';
import {useTranslation} from 'react-i18next';
import {NavLinks} from '../../router';
import PartnersList from '../../components/PartnersList/PartnersList';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {Link} from 'react-router-dom';

import './DronesPage.css';

import Box from '@mui/joy/Box';
import DonationWidget from '../FPVDronesMonoDanationPage/DonationWidget';

function DronesPage() {
    const {t} = useTranslation();

    const {
        data: dronesPage,
        loading,
        error
    } = useFetch<DronesPageResponseDataItem>('/drones-page/?populate[0]=monoBankaText,monoBankaLink,monoBankaQrCode,mainImage,mainImageColor,descriptionImage,descriptionBgImage,counterBgImage,counterImage,partners,purchaseReports,localizations.purchaseReports&populate[1]=partners.logo,purchaseReports.militaryBaseChevron');

    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    if (loading) {
        return (<Loader/>);
    }

    if (error || !dronesPage) {
        return (<></>);
    }

    return (
        <div>
            <ScrollToTop/>

            <article className="drones-page__main-img" style={{backgroundColor: dronesPage.attributes.mainImageColor}}>
                <img loading="lazy"
                     src={MediaUrl(dronesPage.attributes.mainImage, 'large', true)} alt=""/>
            </article>

            <article className="drones-page__description-block">
                <h4 className="drones-page__description-title">
                    <LocalizedText item={dronesPage} field="descriptionTitle"/>
                </h4>
                <div className="drones-page__description-block-content">
                    <div className="drones-page__description-img-wrapper">
                        {dronesPage.attributes.descriptionImage?.data &&
                            <div className="drones-page__description-img" ref={ref}
                                 style={{backgroundImage: dronesPage.attributes.descriptionBgImage ? BackgroundImage(dronesPage.attributes.descriptionBgImage, isIntersecting, 'large', true) : undefined}}>
                                <img loading="lazy"
                                     alt="description"
                                     src={MediaUrl(dronesPage.attributes.descriptionImage, 'large', true)}/>
                            </div>
                        }
                    </div>
                    <div className="drones-page__description-content">
                        <LocalizedText item={dronesPage} field="description" markdown={true}/>
                    </div>
                </div>
                <div className="drones-page__description-columns">
                    {dronesPage.attributes.descriptionColumnLeft &&
                        <div>
                            <LocalizedText item={dronesPage} field="descriptionColumnLeft" markdown={true}/>
                        </div>
                    }
                    {dronesPage.attributes.descriptionColumnRight &&
                        <div>
                            <LocalizedText item={dronesPage} field="descriptionColumnRight" markdown={true}/>
                        </div>
                    }
                </div>
            </article>

            <Box sx={{
                    padding: '32px 16px',
                    background: '#eff1f3',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <DonationWidget />
            </Box>

            <div className="drones-page__requisites">
                {dronesPage.attributes.monoBankaText && dronesPage.attributes.monoBankaLink && dronesPage.attributes.monoBankaQrCode && (
                    <Box sx={{ display: 'flex', alignItems: 'start' }}>
                        <Box sx={{ flex: 1, width: '100%' }}>
                            <LocalizedText item={dronesPage} field="monoBankaText" markdown={true}/>
                            <a href={dronesPage.attributes.monoBankaLink} target="_blank" rel="noopener noreferrer">
                                {dronesPage.attributes.monoBankaLink}
                            </a>
                        </Box>
                        <Box sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <a href={dronesPage.attributes.monoBankaLink} target="_blank" rel="noopener noreferrer">
                                <img loading="lazy" src={MediaUrl(dronesPage.attributes.monoBankaQrCode, 'large', true)} alt="QR Code" style={{height: '170px'}}/>
                            </a>
                        </Box>
                    </Box>
                )}

                <div className="drones-page__requisites-content">
                    <h4 className="drones-page__description-title">
                        <LocalizedText item={dronesPage} field="requisitesTitle"/>
                    </h4>
                    <div className="drones-page__description-content">
                        <LocalizedText item={dronesPage} field="requisitesContent" html={true}/>
                    </div>
                </div>

                <div className="drones-page__requisites-link">
                    <Link
                        to={`/${NavLinks.accountDetails}`}
                        target='_self'
                        rel="noopener noreferrer">
                        {t('common.otherRequisites')}
                    </Link>
                </div>
            </div>

            <DronesStats dronePageData={dronesPage}></DronesStats>

            {dronesPage.attributes.partners && <div className="drones-page__partners">
                <h5 className="drones-page__partners-title">
                    <LocalizedText item={dronesPage} field="partnersTitle"/>
                </h5>
                <PartnersList partners={dronesPage.attributes.partners}></PartnersList>
            </div>}
        </div>
    );
}

export default DronesPage;
