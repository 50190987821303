import React, {createRef, useEffect} from 'react';
import {GenericMediaType} from '../../common/models/Generic.model';

import './UmbrellaDialog.css';

type UmbrellaDialogProps = {
    children?: React.ReactNode;
    image: GenericMediaType | null;
    isOpened: boolean;
    closeModal: () => void;
}

const UmbrellaDialog: React.FC<UmbrellaDialogProps> = ({children, isOpened, closeModal}) => {
    const ref = createRef<HTMLDialogElement>();

    useEffect(() => {
        if (isOpened) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [isOpened]);

    return (
        <dialog ref={ref} onCancel={closeModal}>
            {children}

            <button onClick={closeModal}
                    className="dialog__close"
                    type="button">
            </button>
        </dialog>
    );
};

export default UmbrellaDialog;
