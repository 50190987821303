import styled from '@emotion/styled';

export const Container = styled.div<{ isTextLong?: boolean; children?: React.ReactNode }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: left;
  width: 80%;
  margin: 0 auto;

  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 10px;
  padding-bottom: 20px;

  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
  }

  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  p {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;
