import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TranslateButton from '../TranslateButton/TranslateButton';
import HelpButton from '../HelpButton/HelpButton';
import {HeaderProps} from '../Header/Header.model';
import {Link, useLocation} from 'react-router-dom';

import './Navigation.css';
import {NavLinks} from '../../router';


function Navigation(props: HeaderProps) {
    const {t} = useTranslation();
    const [menuVisibility, changeMenuVisibility] = useState<boolean>(false);
    const location = useLocation();
    const showHelpButton =
        location.pathname !== `/${NavLinks.fpvDronesDonationPage}`
        && location.pathname !== `/${NavLinks.accountDetails}`
        && location.pathname !== `/${NavLinks.podiakaSylamOboronyPage}`;

    const getNavLinkClass = (link: string): string => {
        const hash = location.hash.replace('#', '');
        // For some sections we have pages that provide more details (format - section hash + '-page')
        // And we need to highlight the menu item for such pages as well
        const locationPath = location.pathname
            .replace('/', '');

        const path = locationPath
            .replace('-page', '');

        const isActive = link === locationPath || link === hash || link === path;

        return `navigation__link ${isActive ? 'navigation__link_active' : ''}`;
    }

    const toggleMenu = () => {
        changeMenuVisibility(!menuVisibility);
        props.toggleFader(!menuVisibility);
    }

    const hideMenu = () => {
        changeMenuVisibility(false);
        props.toggleFader(false);
    }

    return (
        <nav className={`navigation ${menuVisibility ? 'navigation_visible' : ''}`}>
            <ul className="navigation__list">
                <li className="navigation__item">
                    <Link to={`/#${NavLinks.about}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.about)}><span>{t('menu.about')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/#${NavLinks.fundraisings}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.fundraisings)}><span>{t('menu.projects')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/#${NavLinks.reports}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.reports)}><span>{t('menu.reports')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/#${NavLinks.partners}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.partners)}><span>{t('menu.partners')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/#${NavLinks.contacts}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.contacts)}><span>{t('menu.contacts')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/${NavLinks.gratitudesPage}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.gratitudesPage)}><span>{t('menu.gratitudes')}</span></Link>
                </li>
                <li className="navigation__item">
                    <Link to={`/${NavLinks.dronesPage}`}
                          onClick={hideMenu}
                          className={getNavLinkClass(NavLinks.dronesPage)}><span>{t('menu.drones')}</span></Link>
                </li>
                <li className="navigation__item navigation__translation_mobile">
                    <TranslateButton/>
                </li>
            </ul>
            <div className="navigation__controls">
                <div className="navigation__translation">
                    <TranslateButton/>
                </div>
                {showHelpButton && <HelpButton link={location.pathname.replace('/', '') === NavLinks.dronesPage ? NavLinks.fpvDronesDonationPage : undefined}/>}
                <button type="button"
                        aria-label={t('menu.kebabBtnAriaLabel') || ''}
                        className="navigation__kebab"
                        onClick={toggleMenu}></button>
            </div>
        </nav>
    );
}

export default Navigation;
