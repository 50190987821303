import React, {useState} from 'react';
import {GenericMediaType, ListResponse, ResponseDataItem} from '../../common/models/Generic.model';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {Gratitude} from '../../pages/GratitudesPage/GratitudesPage.model';
import LocalizedText from '../LocalizedText/LocalizedText';
import {BackgroundImage, MediaUrl} from '../../common/utils/Media';
import {useTranslation} from 'react-i18next';
import UmbrellaDialog from '../UmbrellaDialog/UmbrellaDialog';

import './GratitudesList.css';


type GratitudesListProps = {
    gratitudes: ListResponse<ResponseDataItem<Gratitude>>;
}

const GratitudesList: React.FC<GratitudesListProps> = ({gratitudes}) => {
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});
    const {i18n} = useTranslation();
    const [dialogImage, changeDialogImg] = useState<GenericMediaType | null>(null);
    const [dialogOpenState, changeDialogOpenState] = useState<boolean>(false);

    function showModal(img: GenericMediaType) {
        changeDialogImg(img);
        changeDialogOpenState(true);
    }

    return (
        <div>
            <UmbrellaDialog image={dialogImage}
                            isOpened={dialogOpenState}
                            closeModal={() => changeDialogOpenState(false)}>
                {dialogImage &&
                    <img className="dialog__image"
                         src={MediaUrl(dialogImage, 'large')}
                         alt="Gratitude"/>
                }
            </UmbrellaDialog>

            <div className="gratitudes__list" ref={ref}>
                {gratitudes.data
                    .sort((a, b) => new Date(b.attributes.date).valueOf() - new Date(a.attributes.date).valueOf())
                    .map(gratitude => (
                        <article className="gratitude-item" key={gratitude.id} ref={ref}>
                            <div className="gratitude-item__image"
                                 onClick={() => showModal(gratitude.attributes.image)}
                                 style={{backgroundImage: BackgroundImage(gratitude.attributes.image, isIntersecting)}}></div>
                            <div className="gratitude-item__content">
                                <header>
                                    <h3 className="gratitude-item__content-title">
                                        <LocalizedText item={gratitude} field="title"/>
                                    </h3>
                                </header>
                                <p className="gratitude-item__content-description">
                                    <LocalizedText item={gratitude} field="description"/>
                                </p>
                                <div className="gratitude-item__bottom">
                                    <div className="gratitude-item__chevron"
                                         style={{backgroundImage: BackgroundImage(gratitude.attributes.chevron, isIntersecting)}}></div>
                                    <div className="gratitude-item__date">
                                        {
                                            new Date(gratitude.attributes.date).toLocaleDateString(i18n.resolvedLanguage, {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric'
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
            </div>
        </div>
    );
};

export default GratitudesList;
