import React from 'react';
import HelpButton from '../../components/HelpButton/HelpButton';
import {useTranslation} from 'react-i18next';
import {ContactsResponseDataItem} from './Contacts.model';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import SocialLink from '../../components/SocialLink/SocialLink';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';
import {NavLinks} from '../../router';
import {Link, useLocation} from 'react-router-dom';

import './Contacts.css';

const isPublicOfferDocument = (document: { name: string }) => {
    if (document.name === 'Public offer' || document.name === 'Публічна оферта') {
        return true;
    }

    return false;
}

function Contacts() {
    const {t, i18n} = useTranslation();
    const location = useLocation();

    const showHelpButton =
        location.pathname !== `/${NavLinks.fpvDronesDonationPage}`
        && location.pathname !== `/${NavLinks.accountDetails}`
        && location.pathname !== `/${NavLinks.podiakaSylamOboronyPage}`;
    const isDronesPage = location.pathname === `/${NavLinks.dronesPage}`;

    const currentLang = i18n.resolvedLanguage;

    const {
        data: contactsInfo,
        loading,
        error
    } = useFetch<ContactsResponseDataItem>('/contact/?populate[0]=documents,socialMedias,localizations&populate[1]=localizations.documents.file.media&populate[2]=documents.file.media', NavLinks.contacts);

    if (loading) {
        return (<Loader/>);
    }

    if (error || !contactsInfo) {
        if (!showHelpButton) {
            return (<></>);
        }
        return (
            <div className="contacts-section" id={NavLinks.contacts}>
                <HelpButton isBig={true}/>
            </div>
        );
    }

    return (
        <div className="contacts-section">
            <div className="section__anchor" id={NavLinks.contacts}></div>
            {showHelpButton && <HelpButton isBig={true}
                                           link={isDronesPage ? NavLinks.fpvDronesDonationPage : NavLinks.accountDetails}/>}

            <div className="contacts-section__info">
                <Link to="/" className="logo">Umbrella UA</Link>
                <div className="contacts-section__info-data">
                    <h3 className="contacts-section__info-data-title">{t('contactsSection.contactsTitle')}</h3>
                    <div className="contacts-section__info-data-address">
                        <Link to={`mailto:${contactsInfo.attributes.email}`}>{contactsInfo.attributes.email}</Link>
                        <div className="contacts-section__info-data-address-text">
                            <LocalizedText item={contactsInfo} field="address" html={true}/>
                        </div>
                    </div>
                </div>
                {!!contactsInfo.attributes.documents?.length &&
                    <div className="contacts-section__documents">
                        <h3 className="contacts-section__info-social-title">{t('contactsSection.documentsTitle')}</h3>
                        <ul className="contacts-section__info-document-links">
                            {
                                contactsInfo.attributes.locale === currentLang
                                    ? contactsInfo.attributes.documents
                                        .map(document => (
                                            <li>
                                                {isPublicOfferDocument(document) ?
                                                    (<Link key={document.id} to={`/${NavLinks.publicOfferPage}`}>
                                                        {document.name}
                                                    </Link>)
                                                    : (document.file.data.attributes.url ?
                                                        <Link key={document.id} to={document.file.data.attributes.url}
                                                              download={document.file.data.attributes.url}>
                                                            {document.name}
                                                        </Link> : '')}
                                            </li>
                                        ))
                                    : contactsInfo.attributes.localizations?.data![0].attributes.documents
                                        .map(document => (
                                            <li>
                                                {isPublicOfferDocument(document) ?
                                                    (<Link key={document.id} to={`/${NavLinks.publicOfferPage}`}>
                                                        {document.name}
                                                    </Link>)
                                                    : (document.file.data.attributes.url ?
                                                        <Link key={document.id} to={document.file.data.attributes.url}
                                                              download={document.file.data.attributes.url}>
                                                            {document.name}
                                                        </Link> : '')}
                                            </li>
                                        ))
                            }
                        </ul>
                    </div>
                }
                <div className="contacts-section__info-social">
                    <h3 className="contacts-section__info-social-title">{t('contactsSection.socialTitle')}</h3>
                    <div className="contacts-section__info-social-links">
                        {contactsInfo.attributes.socialMedias.map(socialMedia => (
                            <SocialLink key={socialMedia.id} socialMedia={socialMedia}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
