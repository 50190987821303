import React, { useState } from 'react';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

interface CopyableTextProps {
  text: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text }) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = useState(t('common.clickToCopy'));
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText(t('common.copied'));
      setShowTooltip(true);
      setTimeout(() => {
        setTooltipText(t('common.clickToCopy'));
      }, 2000);
    });
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Tooltip open={showTooltip} title={tooltipText} placement="top">
      <Typography
        onClick={handleCopy}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default CopyableText;
