import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountDetailsSingleResponseDataItem} from './AccountDetails.model';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {ToastType, useToastContext} from '../../contexts/toast';
import {BackgroundImage} from '../../common/utils/Media';
import Loader from '../../components/Loader/Loader';
import useFetch from '../../hooks/useFetch';
import {Link} from 'react-router-dom';
import {NavLinks} from '../../router';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import './AccountDetails.css';

function AccountDetails() {
    const {t} = useTranslation();
    const addToast = useToastContext();
    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    const {
        data: accountDetails,
        loading,
        error
    } = useFetch<AccountDetailsSingleResponseDataItem>('/account-detail/?populate=*', NavLinks.accountDetails);


    if (loading) {
        return (<Loader/>);
    }

    if (error || !accountDetails) {
        return (<></>);
    }

    function copyToClipboard(data: string) {
        navigator.clipboard.writeText(data)
            .then(() => {
                addToast({
                    type: ToastType.Success,
                    message: t('common.copied')
                })
            })
            .catch();
    }

    return (
        <div className="account-details-section" ref={ref}>
            <div className="section__anchor" id={NavLinks.accountDetails}></div>
            <h2 className="account-details-section__title">{t('accountDetailsSection.title')}</h2>
            <div className="account-details-section__info-block">
                <Link to={accountDetails.attributes.privatLink}
                   style={{backgroundImage: BackgroundImage(accountDetails.attributes.privatIcon, isIntersecting)}}
                   target="_blank"
                   data-test={accountDetails.attributes.privatIcon}
                   rel="noreferrer"
                   className="account-details-section__info-item account-details-section__info-item_privat">
                    <LocalizedText item={accountDetails} field="privatTitle"/>
                </Link>
                <div className="account-details-section__info-item">
                    <LocalizedText item={accountDetails} field="ibanUAHTitle"/>
                    <div className="account-details-section__info-item-value">
                        <span
                            className="account-details-section__info-item-data">{accountDetails.attributes.ibanUAHAccount}</span>
                        <div className="account-details-section__info-item-copy"
                             onClick={() => copyToClipboard(accountDetails.attributes.ibanUAHAccount)}>&nbsp;</div>
                    </div>
                </div>
                <div className="account-details-section__info-item">
                    <LocalizedText item={accountDetails} field="paypalTitle"/>
                    <div className="account-details-section__info-item-value">
                        <span className="account-details-section__info-item-data">
                            {accountDetails.attributes.paypalEmail}
                        </span>
                        <div className="account-details-section__info-item-copy"
                             onClick={() => copyToClipboard(accountDetails.attributes.paypalEmail)}>&nbsp;</div>
                    </div>
                </div>
                <Link to={accountDetails.attributes.monoLink}
                   style={{backgroundImage: BackgroundImage(accountDetails.attributes.monoIcon, isIntersecting)}}
                   target="_blank"
                   rel="noreferrer"
                   className="account-details-section__info-item account-details-section__info-item_mono">
                    <LocalizedText item={accountDetails} field="monoTitle"/>
                </Link>
                <div className="account-details-section__info-item account-details-section__info-item_swift">
                    <div>
                        <LocalizedText item={accountDetails} field="swiftTitle"/>
                    </div>
                    <div className="account-details-section__info-item-value">
                        <span className="account-details-section__info-item-label">EUR</span>
                        <span className="account-details-section__info-item-data">
                            {accountDetails.attributes.swiftEUROAccount}
                        </span>
                        <div className="account-details-section__info-item-copy"
                             onClick={() => copyToClipboard(accountDetails.attributes.swiftEUROAccount)}>&nbsp;</div>
                    </div>
                    <div className="account-details-section__info-item-value">
                        <span className="account-details-section__info-item-label">USD</span>
                        <span className="account-details-section__info-item-data">
                            {accountDetails.attributes.swiftUSDAccount}
                        </span>
                        <div className="account-details-section__info-item-copy"
                             onClick={() => copyToClipboard(accountDetails.attributes.swiftUSDAccount)}>&nbsp;</div>
                    </div>
                    <div className="account-details-section__info-item-value">
                        <span className="account-details-section__info-item-label">SWIFT</span>
                        <span className="account-details-section__info-item-data">
                            {accountDetails.attributes.swiftAccount}
                        </span>
                        <div className="account-details-section__info-item-copy"
                             onClick={() => copyToClipboard(accountDetails.attributes.swiftAccount)}>&nbsp;</div>
                    </div>
                </div>
                <Link
                   to={`/${NavLinks.accountDetails}`}
                   target="_blank"
                   rel="noreferrer"
                   className="account-details-section__info-item account-details-section__info-item_all"
                >
                    <div className="account-details-section__info-item_all-link">
                        <div><LocalizedText item={accountDetails} field="allAccountDetailsTitle"/></div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default AccountDetails;
