import React from 'react';
import useFetch from '../../hooks/useFetch';
import Loader from '../../components/Loader/Loader';
import LocalizedText from '../../components/LocalizedText/LocalizedText';
import {BackgroundImage} from '../../common/utils/Media';
import ScrollToTop from '../../common/utils/ScrollToTop';
import {AboutPageResponseDataItem} from './GratitudesPage.model';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import GratitudesList from '../../components/GratitudesList/GratitudesList';

import './GratitudesPage.css';


function GratitudesPage() {
    const {
        data: gratitudesPage,
        loading,
        error
    } = useFetch<AboutPageResponseDataItem>('/gratitudes-page/?populate[0]=gratitudes,image,localizations&populate[1]=gratitudes.localizations,gratitudes.image,gratitudes.chevron');

    const {isIntersecting, ref} = useIntersectionObserver({freezeOnceVisible: true});

    if (loading) {
        return (<Loader/>);
    }

    if (error || !gratitudesPage) {
        return (<></>);
    }

    return (
        <div ref={ref}>
            <ScrollToTop/>

            <h1 className="page__main-title"
                style={{backgroundImage: BackgroundImage(gratitudesPage.attributes.image, isIntersecting, 'large', true)}}>
                <span className="page__main-title-text"><LocalizedText item={gratitudesPage} field="title"/></span>
            </h1>
            <div className="gratitude-page__description page__description">
                <span className="page__description-text"><LocalizedText item={gratitudesPage} field="description"/></span>
            </div>

            {!!gratitudesPage.attributes.gratitudes &&
                <GratitudesList gratitudes={gratitudesPage.attributes.gratitudes}/>
            }
        </div>
    );
}

export default GratitudesPage;
