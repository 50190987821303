import React from 'react';
import { useTranslation } from 'react-i18next';
import CopyableText from '../../components/CopyableText';
import ScanInPrivatLink from '../../assets/images/banking/scan-with-privat.png';
import MonoScanLink from '../../assets/images/banking/mono.png';
import LiqPayScanLink from '../../assets/images/banking/liqpay.png';
import { Container, Section } from './styles';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import DonationWidget from '../../pages/FPVDronesMonoDanationPage/DonationWidget';

const monoBankLink = 'https://send.monobank.ua/jar/h8zvnpPYF';
const liqPayLink = 'https://www.liqpay.ua/uk/checkout/i98648943573';

const BankCredentials: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography level="h1">{t('bankCredentials.title')}</Typography>
      <Box sx={{
        padding: '32px 16px',
        background: '#eff1f3',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}>
        <DonationWidget />
      </Box>
      <Section>
        <Typography level="h2">{t('bankCredentials.donateToMono')}</Typography>
        <Box display="flex" justifyContent="center" sx={{ py: 1 }} flexDirection="column">
          <Link href={monoBankLink} target="_blank" rel="noopener noreferrer">
            <img src={MonoScanLink} alt="Donate via MonoBank" />
          </Link>
          <Box>
            <Link href={monoBankLink} target="_blank" rel="noopener noreferrer">
              {t('bankCredentials.followLink')}
            </Link>
          </Box>
          <Box>
            <Link href={monoBankLink} target="_blank" rel="noopener noreferrer">
              <CopyableText text={monoBankLink} />
            </Link>
          </Box>
        </Box>
      </Section>
      <Section>
        <Typography level="h2">{t('bankCredentials.donateToLiqPay')}</Typography>
        <Box display="flex" justifyContent="center" sx={{ py: 1 }} flexDirection="column">
          <Link href={liqPayLink} target="_blank" rel="noopener noreferrer">
            <img src={LiqPayScanLink} alt="Donate via LiqPay" />
          </Link>
          <Box>
            <Link href={liqPayLink} target="_blank" rel="noopener noreferrer">
              {t('bankCredentials.followLink')}
            </Link>
          </Box>
          <Box>
            <Link href={liqPayLink} target="_blank" rel="noopener noreferrer">
              <CopyableText text={liqPayLink} />
            </Link>
          </Box>
        </Box>
      </Section>
      <Section>
        <Typography level="h2">{t('bankCredentials.ibanSectionTitle')}</Typography>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <CopyableText text="UA463052990000026007046228659" />
          <Box sx={{ py: 1 }} display="flex" flexDirection="column">
            <Typography>{t('bankCredentials.edrpou')}:</Typography>
            <CopyableText text="44808092" />
          </Box>
          <Box sx={{ py: 1 }} display="flex" flexDirection="column">
            <Typography>{t('bankCredentials.purposeOfPayment')}:</Typography>
            <CopyableText text={t('bankCredentials.charitableHelpForMilitary')} />
          </Box>
        </Box>
      </Section>
      <Section>
        <Typography level="h2">{t('bankCredentials.swiftTransfers')}</Typography>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          {[{ currency: 'eur', iban: 'UA643052990000026004046234936' },
            { currency: 'usd', iban: 'UA673052990000026006036238382' },
            { currency: 'pln', iban: 'UA053052990000026003016218994' },
            { currency: 'gbp', iban: 'UA173052990000026008046236907' },
            { currency: 'czk', iban: 'UA333052990000026006006227873' },
            { currency: 'jpy', iban: 'UA573052990000026001006230133' },
            { currency: 'kzt', iban: 'UA323052990000026004006228056' },
            { currency: 'sek', iban: 'UA223052990000026002036233314' },
            { currency: 'nok', iban: 'UA503052990000026002016228985' },
            { currency: 'chf', iban: 'UA043052990000026007036227842' },
          ].map(({ currency, iban }) => (
            <Box key={currency} sx={{ py: 1 }} display="flex" flexDirection="column">
              <Typography>{t(`bankCredentials.ibanCurrency.${currency}`)}</Typography>
              <CopyableText text={iban} />
            </Box>
          ))}
        </Box>
      </Section>
      <Section>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Typography>{t('bankCredentials.bankName')}</Typography>
          <CopyableText text='JSC CB "Privatbank", 1D Hrushevskoho Str., Kyiv, 01001, Ukraine' />
        </Box>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Typography>{t('bankCredentials.swiftCode')}:</Typography>
          <CopyableText text="PBANUA2XXXX" />
        </Box>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Typography>{t('bankCredentials.companyAddress')}:</Typography>
          <CopyableText text="UA 01033, УКРАЇНА, М. КИЇВ, ВУЛ. САКСАГАНСЬКОГО, Б. 41" />
        </Box>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Typography>{t('bankCredentials.purposeOfPayment')}:</Typography>
          <CopyableText text={t('bankCredentials.charitableHelpForMilitary')} />
        </Box>
      </Section>
      <Section>
        <Typography level="h2">{t('bankCredentials.crypto')}</Typography>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Typography>
            <b>BTC:</b>
            <i> {t('bankCredentials.btc')}</i>
          </Typography>
          <Typography>
            <b>USDT:</b>
            <i> {t('bankCredentials.usdt')}</i>
          </Typography>
        </Box>
      </Section>
      <Section>
        <Typography level="h2">{t('bankCredentials.paypal')}</Typography>
        <Box sx={{ py: 1 }} display="flex" flexDirection="column">
          <Box>
            <Typography>
              <b>USDT:</b>
              <i> {t('bankCredentials.usdt')}</i>
            </Typography>
          </Box>
          <Box>
            <CopyableText text="avaxus@ukr.net" />
          </Box>
        </Box>
      </Section>
      <Section>
        <Typography>
          <b>
            <i>{t('bankCredentials.scanPrivat24')}</i>
          </b>
        </Typography>
        <Box display="flex" justifyContent="center">
          <Link href={monoBankLink} target="_blank" rel="noopener noreferrer">
            <img src={ScanInPrivatLink} alt="Donate via Privat24" />
          </Link>
        </Box>
      </Section>
    </Container>
  );
};

export default BankCredentials;
